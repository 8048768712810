<script>
  import { mapGetters, mapActions } from 'vuex'
  import _address from '@/utils/filters/address'
  import { useRouter } from 'vue-router'
  /**
   * A renderless component that pulls authenticated user from vuex store and passes it to child old__components
   *
   * ***********
   * Passing user object:
   * ************
   * <x-user v-slot="user">
   *   <h1> heres the user in json format </h1>
   *   <span>{{ user.user }}</span>
   * </x-cart>
   *
   * OR as deconstructed object
   *
   * <x-user v-slot="{ user }">
   *   <h1> heres the user in json format </h1>
   *   <span>{{ user }}</span>
   * </x-cart>
   *
   * ************
   * Getting selected address
   * ************
   * <x-user v-slot="user">
   *   <h1> heres the currently selected address in json format </h1>
   *   <span>{{ user.selectedAddress }}</span>
   * </x-user>
   *
   */
  export default {
    name: 'XUser',
    inheritAttrs: false,
    props: {
      /**
       * If true, the user must be authenticated to view the data in the slot of this component. IF not authenticated,
       * slot content will not be rendered and the login popup will appear prompting user to login
       */
      auth: {
        type: Boolean,
        default: false
      }
    },
    emits: ['address'],

    setup() {
      const router = useRouter()

      // Setup can also include onBeforeRouteUpdate or provide reactive access to the route and store
      return { router }
    },

    computed: {
      ...mapGetters('authuser', ['xCurrentUser', 'xGetAddressList', 'xIsAuthenticated']),
      ...mapGetters('user-region', ['xSelectedAddress']),
      ...mapGetters('regions', [
        'xFindRegionFromZip',
        'xFindLocationFromZip',
        'xFindPossibleDeliveryDates',
        'xFindNextDeliveryDate'
      ]),

      region() {
        if (this.xSelectedAddress) {
          return this.xFindRegionFromZip(this.xSelectedAddress?.zip)
        }

        return null
      },

      nextDelivery() {
        if (this.xSelectedAddress) {
          return this.xFindNextDeliveryDate(this.region)
        }

        return null
      },

      /**
       * An object that exposes user fields and async actions in an easy to use way
       */
      user() {
        const getRegion = () => {
          const ret = this.xFindRegionFromZip(this.xSelectedAddress?.zip)
          return ret
        }

        const getLocation = () => {
          const ret = this.xFindLocationFromZip(this.xSelectedAddress?.zip)
          return ret
        }

        const findDeliveries = this.xFindPossibleDeliveryDates

        return {
          ...this.xCurrentUser,
          authenticated: !!this.xCurrentUser,
          selectedAddress: this.xSelectedAddress,
          getRegion,
          getLocation,
          findPossibleDeliveryDates(days) {
            return findDeliveries(getRegion(), days)
          },
          nextDelivery: this.nextDelivery,
          nextDeliveryHuman: this.nextDelivery?.human || null,
          region: this.region,
          regionName: this.region?.name || null,
          addressHuman: _address(this.xSelectedAddress)
        }
      }
    },

    watch: {
      /** Called when the selected address changes */
      xSelectedAddress() {
        // Emit an "address" event indicating that the selected event has changed
        this.$emit('address', this.xSelectedAddress)
      }
    },

    methods: {
      ...mapActions('user-region', ['xSelectNewLocation']),
      ...mapActions('authuser', ['xCreateNewAddress', 'xMakeTempAddress']),
      ...mapActions('auth', ['xOpenLoginPopup', 'xCloseLoginPopup', 'xLogout']),
      redirectToHome() {
        if (this.router.options.history.state.back === '/profile/medical') {
          return true
        }
      }
    },

    /**
     * Renders component to the browser page directly instead of using a <template> element
     * @param h an internal vue function that creates a component
     */
    render(h) {
      if (this.redirectToHome()) {
        if (!this.xIsAuthenticated) {
          this.router.push('/account')
          return null
        } else if (!this.xIsAuthenticated && this.auth) {
          this.xOpenLoginPopup()
          return null
        }
      } else if (!this.xIsAuthenticated && this.auth) {
        this.xOpenLoginPopup()
        return null
      }

      return this.$slots.default({
        user: this.user,
        xSelectedAddress: this.xSelectedAddress,
        xGetAddressList: this.xGetAddressList,
        xIsAuthenticated: this.xIsAuthenticated,
        xSelectNewLocation: this.xSelectNewLocation,
        xFindNextDeliveryDate: this.xFindNextDeliveryDate,
        xCreateNewAddress: this.xCreateNewAddress,
        xMakeTempAddress: this.xMakeTempAddress,
        xOpenLoginPopup: this.xOpenLoginPopup,
        xCloseLoginPopup: this.xCloseLoginPopup,
        xLogout: this.xLogout
      })
    }
  }
</script>
